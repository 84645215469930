import React from "react";

function SkillProgress({ lang }) {
  let SkillProgressInner = "skill-progress-inner";
  return (
    <div className="skills-section">
      <div className="skill-progress">
        <div
          className={SkillProgressInner}
          style={{ width: `${lang.percent}%` }}
        >
          <h5>{lang.name}</h5>
        </div>
        <h6>{lang.percent}%</h6>
      </div>
    </div>
  );
}

class Skills extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      counter: 0,
      languages: [
        { name: "HTML", percent: 0 }, //90
        { name: "CSS", percent: 0 }, //80
        { name: "JAVASCRIPT", percent: 0 }, //70
        { name: "PHP", percent: 0 }, //60
        { name: "MYSQL", percent: 0 } //50
      ],
      flameworks: [
        { name: "REACT", percent: 0 }, //70
        { name: "VUE", percent: 0 }, //80
        { name: "NODE/EXPRESS", percent: 0 }, //68
        { name: "JQUERY", percent: 0 }, //65
        { name: "BOOTSTRAP", percent: 0 } //60
      ]
    };
  }

  componentDidMount() {
    document.title = "Tecnologies i work with";
    this.tick1();
    this.Timer();
  }
  Timer() {
    this.HTML = setInterval(() => this.tick1(), 1);
    this.CSS = setInterval(() => this.tick2());
    this.JS = setInterval(() => this.tick3(), 5);
    this.PHP = setInterval(() => this.tick4(), 5);
    this.REACT = setInterval(() => this.tick5(), 5);
    this.VUE = setInterval(() => this.tick6(), 5);
    this.JQ = setInterval(() => this.tick7(), 5);
    this.NODE = setInterval(() => this.tick8(), 5);
    this.MYSQL = setInterval(() => this.tick9(), 5);
    this.BOOTSTRAP = setInterval(() => this.tick10(), 5);
  }

  tick1() {
    let langs = this.state.languages.slice();
    langs[0].percent++;
    this.setState({ languages: langs });

    if (langs[0].percent === 90) {
      clearInterval(this.HTML);
    }
  }

  tick2() {
    let langs = this.state.languages.slice();
    langs[1].percent++;
    this.setState({ languages: langs });
    if (langs[1].percent === 80) {
      clearInterval(this.CSS);
    }
  }
  tick3() {
    let langs = this.state.languages.slice();
    langs[2].percent++;
    this.setState({ languages: langs });
    if (langs[2].percent === 70) {
      clearInterval(this.JS);
    }
  }
  tick4() {
    let langs = this.state.languages.slice();
    langs[3].percent++;
    this.setState({ languages: langs });
    if (langs[3].percent === 68) {
      clearInterval(this.PHP);
    }
  }

  tick5() {
    let flame = this.state.flameworks.slice();
    flame[0].percent++;
    this.setState({ flameworks: flame });

    if (flame[0].percent === 90) {
      clearInterval(this.REACT);
    }
  }

  tick6() {
    let flame = this.state.flameworks.slice();
    flame[1].percent++;
    this.setState({ flameworks: flame });
    if (flame[1].percent === 80) {
      clearInterval(this.VUE);
    }
  }
  tick7() {
    let flame = this.state.flameworks.slice();
    flame[3].percent++;
    this.setState({ flameworks: flame });
    if (flame[3].percent === 68) {
      clearInterval(this.JQ);
    }
  }
  tick8() {
    let flame = this.state.flameworks.slice();
    flame[2].percent++;
    this.setState({ flameworks: flame });
    if (flame[2].percent === 65) {
      clearInterval(this.NODE);
    }
  }
  tick9() {
    let langs = this.state.languages.slice();
    langs[4].percent++;
    this.setState({ languages: langs });
    if (langs[4].percent === 60) {
      clearInterval(this.MYSQL);
    }
  }
  tick10() {
    let flame = this.state.flameworks.slice();
    flame[4].percent++;
    this.setState({ flameworks: flame });
    if (flame[4].percent === 60) {
      clearInterval(this.BOOTSTRAP);
    }
  }

  render() {
    const LanguagesList = [];
    const FlameworksList = [];
    this.state.languages.map(lan => {
      return LanguagesList.push(<SkillProgress lang={lan} />);
    });
    this.state.flameworks.map(lan => {
      return FlameworksList.push(<SkillProgress lang={lan} />);
    });

    return (
      <div className="container">
        <div className="skills-inner-content">
          <div className="paragraph-section">
            <h2>What i know</h2>
            <h3>My Experience</h3>
            <p>
              Everything started as a passion until today. I've been working
              with all these technologies and they helped me to be a better me
              in my carrer. for any question you're free to contact me.
            </p>
          </div>
          <div className="skills-grid-container">
            <div>
              <h6>
                LANGUAGES
                <hr />
              </h6>
              <div className="lang-list">{LanguagesList}</div>
            </div>
            <div>
              <h6>
                FLAMEWORKS
                <hr />
              </h6>
              <div className="flam-list"> {FlameworksList}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Skills;
