import React from "react";


class Projects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    document.title = "rcx-dev | Done Jobs";
  }

  render() {
    return (
      <div className="container">
        <h6>Projects</h6>
      </div>
    );
  }
}

export default Projects;
