import React, {useState} from "react";
import { Button, Card } from "@material-ui/core";
import "./App.css";
import SideNav from "./components/SideNav";
const url =
  "https://z-p3-scontent.flun3-1.fna.fbcdn.net/v/t1.0-9/23559432_817095258471611_9170229219034578044_n.jpg?_nc_cat=106&_nc_oc=AQnx1VxyyqJ00Y2sLSS29L3-VTOLi5lMzM759qkTMHvdBt54g3NBnn4dED_ziMMyG7Y&_nc_ht=z-p3-scontent.flun3-1.fna&oh=6eb6e5bdeaf760b0ba6edfbabd15b9a0&oe=5DE0046B";


function Intro (){
  return (
    <div className="name-section">
      <h2>HI, I'M ROBERT, Web developer.</h2>
      <span>I'm passionate, i love Developing for the web...</span>
      <br />
      <span>I Develop both Front End & Backened stuff (Fullstack)</span>
    </div>
  );
}
function ContactInfo (){
  return (
    <div className="contact-section">
        <h4>Email: robertchingambu65@gmail.com</h4>
        <h4>Contact: +260 961598265</h4>
        <h4 style={{ marginLeft:'75px' }}> +260 964827318</h4>
    </div>
  );
}



  function ProfilePic() {
    const [showContact, setShowContact] = useState(false);
    function showContacts(){
      setShowContact((showContact) => showContact = !showContact);
    }
  return (
    <div className="profile-wrap">
      <div className="profile-inner-container">
        <div className="profile-inner-wrap">
          <div className="profile">
            <div className="profile-inner-two">
              <div className="profile-inner-one">
                <img src={url} alt="Robert Profile Cap" />
              </div>
            </div>
          </div>
          {showContact ? <ContactInfo /> : <Intro />}

          <div className="contact-butn-wrap">
            <Button
              onClick={showContacts}
              variant="outlined"
              className="contact-button"
              color="secondary"
            >
              {showContact? 'Done, Go back':'Contact Me'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

function App() {
  return (
    <React.Fragment>
      <div className="App">
        <ProfilePic />
      </div>
      <Card className="card-main-nav">
        <SideNav />
      </Card>
    </React.Fragment>
  );
}

export default App;
