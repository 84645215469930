import React from 'react';


class HomeProfile extends React.Component {
    constructor(props){
        super(props)
        this.state = {

        };
    }
    componentDidMount(){
          document.title = "rcx-dev | Home";
    }

    render(){
        return(
            <div className="container">
            <h6>Home</h6>
            </div>
        )
    }
}



export default HomeProfile;