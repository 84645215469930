import React from "react";

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount(){
    document.title = 'About me';
  }

  render() {
    return (
      <div className="container">
        <div className="inner-content-about">
          <h2>About Me</h2>
          <h3>Hi,My name is Robert Ching'ambu thanks for meeting me.</h3>
          <p>
            I'am a passionated web developer (self taught) for neary 3years now,
            i can build fast and interactive web apps and websites. Developing
            for the web is what i love and writing code is what i enjoy i can
            work for for you
          </p>
        </div>
      </div>
    );
  }
}

export default About;
