import React from "react";
import { Card } from "@material-ui/core";
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import HomeProfile from "./HomeProfile";
import home from "../Assets/go-home.png";
import about from "../Assets/about-me.png";
import skills from "../Assets/skills.png";
import projects from "../Assets/projects.png";
import Skills from "./Skills";
import Projects from "./Projects";
import About from "./About";
function SideNav() {
  function ScrollTop() {
    return window.scrollTo({ top: 0 });
  }
  const BdStyle = {
    borderRight: "2px solid #08fdd8"
  };
  return (
    <React.Fragment>
      <Router>
        <Card className="side-nav-wrap">
          <NavLink to="/" exact activeStyle={BdStyle}>
            <li onClick={ScrollTop}>
              Home
              <img src={home} alt="Home" />
            </li>
          </NavLink>
          <NavLink to="/about" exact activeStyle={BdStyle}>
            <li>
              About Me
              <img src={about} alt="about me" />
            </li>
          </NavLink>
          <NavLink to="/skills" exact activeStyle={BdStyle}>
            <li>
              Skills
              <img src={skills} alt="skills" />
            </li>
          </NavLink>
          <NavLink to="/projects" exact activeStyle={BdStyle}>
            <li>
              Projects
              <img src={projects} alt=" Projects" />
            </li>
          </NavLink>
        </Card>
        <Route path="/" exact component={HomeProfile} />
        <Route path="/about" exact component={About} />
        <Route path="/skills" exact component={Skills} />
        <Route path="/projects" exact component={Projects} />
      </Router>
    </React.Fragment>
  );
}

export default SideNav;
